import * as Sentry from "@sentry/vue";
import { ref } from "vue";
import { defineStore } from "pinia";
import { useCookies } from "@vueuse/integrations/useCookies";

type AuthStoreData =
  | { accessToken: string | null; refreshToken: string | null; state: "LOADED" }
  | { state: "REFRESHING" }
  | { state: "TOKENS_INVALIDATED" };

type RefreshAccessTokenResponse = {
  access_token: string;
  expires_in_millis: number;
  refresh_token: string;
};

const GRANT_TYPE = {
  REFRESH_TOKEN: "refresh_token",
} as const;

export const useAuthStore = defineStore("auth", () => {
  const runtimeConfig = useRuntimeConfig();
  const { set, remove } = useCookies(["accessToken", "refreshToken"]);
  const cookieAccessToken = useCookie("accessToken").value || null;
  const cookieRefreshToken = useCookie("refreshToken").value || null;
  const data = ref<AuthStoreData>({
    accessToken: cookieAccessToken,
    refreshToken: cookieRefreshToken,
    state: "LOADED",
  });

  const invalidateSession = () => {
    remove("accessToken");
    remove("refreshToken");
    data.value = { state: "TOKENS_INVALIDATED" };
    const redirectUrl = useRoute().fullPath;
    return navigateTo({ path: "/login", query: { redirectUrl } });
  };

  const getCookieAccessToken = () => {
    return useCookie("accessToken").value;
  };

  const getCookieRefreshToken = () => {
    return useCookie("refreshToken").value;
  };

  const setCookieAccessToken = (
    accessToken: string,
    expiresInMillis: number,
  ) => {
    set("accessToken", accessToken, {
      maxAge: expiresInMillis / 1000,
      path: "/",
    });
  };

  const setCookieRefreshToken = (refreshToken: string) => {
    set("refreshToken", refreshToken, {
      maxAge: 60 * 60 * 24 * 365,
      path: "/",
    });
  };

  const getRefreshToken = () => {
    if (data.value.state === "LOADED" && data.value.refreshToken !== null) {
      return data.value.refreshToken;
    }
    return getCookieRefreshToken();
  };

  const getAccessToken = (timeout = 5000) => {
    return new Promise((resolve, reject) => {
      if (data.value.state === "LOADED") {
        resolve(data.value.accessToken);
      } else {
        const startTime = Date.now();
        const interval = setInterval(() => {
          if (data.value.state === "LOADED") {
            clearInterval(interval);
            resolve(data.value.accessToken);
          } else if (Date.now() - startTime >= timeout) {
            clearInterval(interval);
            reject(
              new Error(
                "Timeout: State not loaded within the specified duration.",
              ),
            );
          }
        }, 100);
      }
    });
  };

  const refreshAuthTokens = async () => {
    if (data.value.state === "REFRESHING") {
      return;
    }

    if (data.value.state !== "LOADED" || !getRefreshToken()) {
      const redirectUrl = useRoute().fullPath;
      navigateTo({ path: "/login", query: { redirectUrl } });
      return;
    }

    const refreshToken = getRefreshToken();
    data.value = { state: "REFRESHING" };
    try {
      const res = await $fetch<RefreshAccessTokenResponse>(
        `${runtimeConfig.public.kcOauthUrl}/token`,
        {
          method: "POST",
          body: {
            client_id: runtimeConfig.public.authClientId,
            grant_type: GRANT_TYPE.REFRESH_TOKEN,
            refresh_token: refreshToken,
          },
        },
      );
      if (res.access_token && res.refresh_token) {
        setCookieAccessToken(res.access_token, res.expires_in_millis);
        setCookieRefreshToken(res.refresh_token);
        data.value = {
          state: "LOADED",
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
        };
      } else {
        data.value = { state: "TOKENS_INVALIDATED" };
      }
    } catch (err) {
      Sentry.captureException(err);
      data.value = { state: "TOKENS_INVALIDATED" };
    }
  };

  const isAuthenticated = async () => {
    try {
      const accessToken = await getAccessToken(2000);
      return !!accessToken;
    } catch (err) {
      return false;
    }
  };

  const verifyAuthenticationAndRefreshTokens = async () => {
    if (data.value.state === "REFRESHING") {
      return;
    }
    if (!getRefreshToken()) {
      invalidateSession();
    }
    if (data.value.state === "LOADED" && !getCookieAccessToken()) {
      await refreshAuthTokens();
    }
  };

  return {
    data,
    isAuthenticated,
    getCookieAccessToken,
    getCookieRefreshToken,
    getAccessToken,
    getRefreshToken,
    refreshAuthTokens,
    verifyAuthenticationAndRefreshTokens,
  };
});
